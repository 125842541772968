
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import UserForm from '@/components/forms/UserForm.vue';

@Options({
    components: {
        Breadcrumb, UserForm
    },
    data(){
        return{
            data: {}
        }
    },
    mounted(){
        this.getInfo(Number(this.$route.params.id))
    },
    methods: {
        goBack(){
            this.$router.go(-1);
        },
        getInfo(id: number){
            this.$api.getAdminInfoById(id).then((res: any) => {
                this.data = res.data
            })
        }
    }
})

export default class AdminEdit extends Vue {}
